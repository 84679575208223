import React, { useEffect } from "react";
import proProjectList from "../data/proProjectsList";
import useMediaQuery from "../hooks/useMediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProjectsCarousel from "../pages/home/components/ProProjectsCarousel";

// Images
import trailBlazersBanner from "../assets/images/blazers_court.png";
import portlandTrailBlazers from "../assets/images/portland_trail_blazers.png";
import userFlow from "../assets/images/user_flow.png";
import desktop1 from "../assets/images/desktop_1.png";
import desktop2 from "../assets/images/desktop_2.png";
import mobile from "../assets/images/mobile_all.png";
import components from "../assets/images/components.png";
import projectManagementBoard from "../assets/images/project_management_board.png";
import shaedonSharpe from "../assets/images/player_feature-sharpe.png";
import blazers from "../assets/images/blazers_side-by-side.png";
import abstractSquaredGrid from "../assets/images/square-squared-grid.png";
import abstractPlusGrid from "../assets/images/abstract-plus-grid-2.png";
import abstractDiagonalSquare from "../assets/images/abstract-diagonal-square.png";
import abstractSquaresCorner from "../assets/images/abstract-squares-corner.png";

export default function TrailBlazers() {
  // FOR LAYING DOWN THE UI
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            // pin: ".project__inner-layout-container",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function () {
        gsap.to("#layoutImages", {
          scrollTrigger: {
            trigger: "#layoutImages",
            start: "top top",
            pin: "#layoutContent",
            end: "bottom bottom",
            scrub: true,
            nullTargetWarn: false,
            // markers: "true",
          },
        });
      },
    });
  }, []);

  const isMobile = useMediaQuery("(min-width:991px)");

  return (
    <div>
      {/* HEADER */}
      <section className="project__container">
        <img
          className="project__main-image"
          src={trailBlazersBanner}
          alt="Trail Blazers court during a basketball game."
        />
        <img
          className="project__main-image-abstract"
          src={abstractSquaredGrid}
          alt="Abstract. Grid of squares."
        />

        <div className="project__main-info d-flex align-items-center">
          <p className="project__main-project-number">01</p>
          <h1 className="project__main-title">Trail Blazers</h1>
        </div>
      </section>

      {/* PROJECT OVERVIEW */}
      <div className="project__details-container">
        <div className="row g-5 pb-5">
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <div className="project__details-section">
              <h4>Employer</h4>
              <p>Portland Trail Blazers</p>
            </div>
            <div className="project__details-section">
              <h4>Business Type</h4>
              <p>NBA Team</p>
            </div>
            <div className="project__details-section">
              <h4>Website</h4>
              <p>Exclusively for Basketball Operations</p>
            </div>
            <div className="project__details-section">
              <h4>Completed</h4>
              <p>In progress</p>
            </div>
            <div className="project__details-section">
              <h4>Status</h4>
              <p>Software continues to evolve</p>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-7 col-lg-7"
            style={{ marginTop: "5px" }}
          >
            <p
              style={{
                fontFamily: "'Square Peg', cursive",
                fontWeight: 400,
                fontStyle: "normal",
                textAlign: "right",
                fontSize: "40px",
              }}
            >
              "I don't watch basketball..."
            </p>
            <p>
              One morning, I received an email asking if I was interested in
              learning about an opportunity with the Portland Trail Blazers. A
              team I hadn’t heard of, for a sport I wasn’t a fan of, and
              thought, “Why not?”{" "}
              <b>I’m adaptable. I love a challenge. I climb over obstacles.</b>
            </p>
            <p>
              I had experience working with all kinds of businesses, so sports?
              Bring it on! With that mindset I took the interview. After
              admitting I knew very little about basketball and having a laugh,
              I learned about the organization and their needs.
            </p>
            <p>
              They were starting from scratch, building software from the ground
              up. Developing tools, applications, and systems that would allow
              for automated and data-driven workflows for basketball operations:
              coaching staff, medical team, management.
            </p>
            <p>
              I was beyond excited; a fan in the making. I hit the ground
              running.{" "}
            </p>
          </div>
        </div>

        {/* CONTRIBUTIONS */}
        <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
          <img
            src={abstractPlusGrid}
            alt="Grid of plus symbol."
            className="project__contribution-image"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <h4 className="mb-5">Contributions</h4>

            <div className="row g-5 pt-5">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>Software Design</p>
                <hr />
                <p>Wireframing</p>
                <hr />
                <p>UX Research</p>
                <hr />
                <p>UI Design</p>
                <hr />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 project__contributions-item">
                <p>Front-end development</p>
                <hr />
                <p>Component Library</p>
                <hr />
                <p>Project Management</p>
                <hr />
                <p>Content Creation</p>
                <hr />
              </div>
            </div>
          </div>
        </div>

        {/* RESEARCH RAMP UP */}
        <div className="row mt-5 pb-5 g-5 d-flex align-items-center">
          <div className="col-12 col-sm-12 col-md-7 col-lg-7 pe-7">
            <h4 className="mb-5">Research Ramp Up</h4>
            <p>
              Just like with every project I dive into, I went all in on the
              research. I immersed myself in the sport, studying the
              organization, games, scoring systems, and player stats. I watched
              live games and highlights, and clicked through sports-related
              websites. I even delved into fan forums and social media to
              capture the buzz and fan expectations.
            </p>
            <p>
              I also explored the team’s legacy software and looked into other
              similar software. To round out my research, I studied historical
              data to understand past performances. All this research was aimed
              at identifying what information is most useful to scouts so I
              could design and develop the right tools for them.
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-5 project__tools-box">
            <img
              src={abstractDiagonalSquare}
              alt="Grid of diagonal lines in square."
              className="project__tools-image-background"
            />
            <img
              src={abstractSquaresCorner}
              alt="Grid of plus symbol."
              className="project__tools-image"
            />
            <h4 className="mb-5">Environment & Tools</h4>
            <ul className="project__tool-list">
              <li>React</li>
              <li>Javascript</li>
              <li>Typescript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>Sass</li>
              <li>MUI</li>
              <li>Storybook</li>
              <li>GitHub Kanban</li>
              <li>Adobe XD</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
            </ul>
          </div>
        </div>
      </div>

      {/* DISCLAIMER */}
      <div className="project__details-full-container blazers-quick-note row align-items-en">
        <div className="row mt-5 mb-5">
          <div className="col-12 mb-5">
            <h4 className="mb-5">A Quick Note...</h4>
            <p>
              All graphics on this page were created just for this case
              study—they’re not actual work projects. Think of them as my
              creative playground. Actual sketches, wireframes, designs, and
              presentations are top-secret, hush-hush stuff to keep things
              confidential and protect the organization’s intellectual property.
              The info here covers basic software development concepts and my
              role in the process.
            </p>
          </div>

          <img
            src={portlandTrailBlazers}
            alt="Portland Trail Blazers Logo with Background of Portland skyline."
          />
        </div>
      </div>

      {/* LAYING DOWN THE DESIGN */}
      <div
        id="mainContainer"
        className="project__blazers-layout-container clearfix"
      >
        <div
          id="layoutContainer"
          className="project__blazers-layout-container-inner"
        >
          <div id="layoutContent" className="project__blazers-layout-desc">
            <div
              id="description"
              className="project__blazers-layout-content-inner"
            >
              <div>
                <h4 className="mb-5">Laying the Groundwork: UX/UI Planning</h4>
                <p>
                  Initial planning sessions were pivotal to the development
                  process. On my first day in the office, I grabbed a dry erase
                  marker and sketched out flows on the whiteboard as the
                  development team discussed the needs and wants of the scouting
                  department, along with their thoughts and ideas.
                </p>
                <p>
                  Flow charts were invaluable for mapping out the user journey
                  and uncovering any blind spots in the user experience.
                  Low-to-medium fidelity wireframes allowed me to outline all
                  the sections and components I needed to design. From these
                  wireframes, I created full designs that were then reviewed and
                  refined based on feedback.
                </p>
              </div>
            </div>
          </div>

          <div id="layoutImages" className="project__blazers-container-images">
            <img
              src={userFlow}
              className="img-fluid mb-5 project__images"
              alt="User Flow chart."
            />
            <img
              src={desktop1}
              className="img-fluid mb-5 project__images"
              alt="Desktop wireframe."
            />
            <img
              src={desktop2}
              className="img-fluid mb-5 project__images"
              alt="Desktop wireframe."
            />
            <img
              src={mobile}
              className="img-fluid mb-5 project__images"
              alt="Mobile wireframe."
            />
          </div>
        </div>
      </div>

      {/* COMPONENTS */}
      <div className="project__details-full-container row align-items-end">
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-5"></div>
          <div className="col-12 col-sm-12 col-md-9 col-lg-9 mb-5">
            <h4 className="mb-5">
              Design Realized: Pixel-Perfect Front-end Development
            </h4>
            <p>
              To streamline the front-end development process, I introduced
              Storybook, a powerful tool for building and documenting UI
              components. This approach allowed us to develop and iterate on
              components in isolation, speeding up the overall development
              process.
            </p>
            <p>
              I took my designs and meticulously developed the front end,
              ensuring each element was pixel-perfect. Using Storybook, I was
              able to handle the front-end development of the main components
              efficiently, creating a robust and cohesive user interface that
              matched the initial designs flawlessly. This not only accelerated
              development but also maintained a high standard of quality and
              consistency across the project.
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3"></div>

          <img src={components} alt="Wireframes of various components." />
        </div>
      </div>

      {/* PROJECT MANAGEMENT */}
      <div className="project__details-container row align-items-end">
        <div
          className="row mt-5 mb-5"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="col-12 mb-5">
            <h4 className="mb-5">
              Project Management: Building a better Workflow
            </h4>
            <p>
              Project management is essential to the success of development
              projects, especially when a team expands beyond a party of one.
              Initially, we had no formal project management process in place.
              Recognizing the need for better organization, I introduced and
              championed the idea of project management.
            </p>
            <p>
              At first, the team was hesitant to incorporate it into their
              workflow, so I took the initiative to follow my own project
              management process using Asana. As our project continued to grow
              and evolve, the team saw the benefits of a structured approach and
              became interested in finding a better way to manage our work. We
              transitioned to using the GitHub Kanban board, where I organized
              tasks into stories, tags, sprints, etc. This structure made it
              easy for us to track progress, collaborate effectively, and ensure
              we stayed on top of our development goals.
            </p>
          </div>

          <img
            src={projectManagementBoard}
            alt="Wireframe of a project management board."
          />
        </div>
      </div>

      {/* GRAPHIC DESIGN */}
      <div className="project__details-container row pt-5 pb-5 mt-5 spacing-5">
        <div
          className="
          col-sm-12 col-md-12 col-lg-7
          order-2 order-md-2 order-lg-1
          blazers-sharpe-image
        "
        >
          <img
            src={shaedonSharpe}
            className="img-fluid w-100 mb-5 project__images"
            alt="Shaedon Sharpe player sheet with stats."
          />
        </div>
        <div
          className="
          col-sm-12 col-md-12 col-lg-5
          order-1 order-md-1 order-lg-2 d-flex flex-column justify-content-center pb-5
        "
        >
          <h4 className="mb-5">Crafting Engaging Visuals for Impact</h4>
          <p>
            Design is more than just good fonts and a nice color palette. It
            influences user behavior and impacts how information is absorbed.
            While designing the software and building the front end was one
            aspect of my role, I also applied my graphic design skills to
            separate projects: creating documents for the coaching staff,
            scouting department, medical team, ownership and more. From
            PowerPoint presentations and player sheets to itineraries and more,
            I ensured every visual element of these materials was polished,
            professional, and engaging.
          </p>
        </div>
      </div>

      {/* BLAZERS IMAGE */}
      <div className="project__details-container row pt-3 pb-5">
        <div
          className="
          col-sm-12 col-md-12 col-lg-4
        "
        ></div>
        <div
          className="
          col-sm-12 col-md-12 col-lg-8
        "
        >
          <img
            src={blazers}
            className="img-fluid w-100 mb-5 project__images"
            alt="Four Trail Blazers players, side by side."
          />
        </div>
      </div>

      {/* MORE PROJECTS CAROUSEL */}
      <ProjectsCarousel proProjectList={proProjectList} isMobile={isMobile} />

      {/* END */}
    </div>
  );
}
