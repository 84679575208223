import React from 'react';
import { useLocation } from 'react-router-dom';
// import useMediaQuery from "../hooks/useMediaQuery";
import Carousel from "../../../components/ProjectsCarousel";



export default function ProProjectsCarousel({proProjectList, isMobile}) {
    // (See Carousel Hooks)
    // const isMobile = useMediaQuery("(min-width:991px)");
    const location = useLocation();
    const currentSlug = location.pathname.split('/').pop();
  
    const filteredProjectList = proProjectList.filter(project => project.slug !== currentSlug);

  return (
      <div className="project__details-container project__carousel">
        {isMobile ? (
          <Carousel show={2}>
            {filteredProjectList.map(({ id, image, title, number, role, slug }) => (
              <div key={id} className="project__more-container">
                <div style={{ padding: "50px" }}>
                  <a href={/project/ + slug}>
                    <img
                      src={image}
                      alt={title}
                      className="project__more-images"
                    />
                    <div
                      className="d-flex align-items-center"
                      style={{ color: "#000000" }}
                    >
                      <p className="project__more-number pe-4">{number}</p>
                      <div>
                        <h4>{title}</h4>
                        <p>{role}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel show={1}>
            {filteredProjectList.map(({ id, image, title, number, role, slug }) => (
              <div key={id} className="project__more-container">
                <div style={{ padding: "50px" }}>
                  <a href={/project/ + slug}>
                    <img
                      src={image}
                      alt={title}
                      className="project__more-images"
                    />
                    <div
                      className="d-flex align-items-center"
                      style={{ color: "#000000" }}
                    >
                      <p className="project__more-number pe-4">{number}</p>
                      <div>
                        <h4>{title}</h4>
                        <p>{role}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
  )
}
