import React from "react";

export default function ContactMain() {
  return (
    <section className="contactMain__container d-flex align-items-center">
      <div className="row g-5 d-flex align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h3>Contact</h3>
          <h2>Let's create something together...</h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <a
            href="mailto:juanita.samborski@gmail.com"
            role="button"
            className="d-flex justify-content-between align-items-center contactMain-links"
          >
            Email
            <i className="bi bi-arrow-right contact-links-arrow"></i>
          </a>
          <hr className="contactMain-links-divider" />
          <a
            href="https://github.com/jsamborski310"
            rel="noreferrer"
            target="_blank"
            role="button"
            className="d-flex justify-content-between align-items-center contactMain-links"
          >
            Github
            <i className="bi bi-arrow-right contactMain-links-arrow"></i>
          </a>
          <hr className="contactMain-links-divider" />
          <a
            href="https://www.linkedin.com/in/juanita-samborski/"
            rel="noreferrer"
            target="_blank"
            role="button"
            className="d-flex justify-content-between align-items-center contactMain-links"
          >
            Linkedin
            <i className="bi bi-arrow-right contactMain-links-arrow"></i>
          </a>
          <hr className="contactMain-links-divider" />
          <a
            href="https://twitter.com/SamborskiUp"
            rel="noreferrer"
            target="_blank"
            role="button"
            className="d-flex justify-content-between align-items-center contactMain-links"
          >
            Twitter
            <i className="bi bi-arrow-right contactMain-links-arrow"></i>
          </a>
          <hr className="contactMain-links-divider" />
          <a
            href="https://bit.ly/samborski-upwork"
            rel="noreferrer"
            target="_blank"
            role="button"
            className="d-flex justify-content-between align-items-center contactMain-links"
          >
            Upwork
            <i className="bi bi-arrow-right contactMain-links-arrow"></i>
          </a>
          <hr className="contactMain-links-divider" />
        </div>
      </div>
    </section>
  );
}
