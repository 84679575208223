import React from "react";
import githubIcon from "../assets/icons/github-black.png";
import linkedinIcon from "../assets/icons/linkedin-in-brands.png";
import emailIcon from "../assets/icons/email-add-black.png";
import upworkIcon from "../assets/icons/upwork-tile.png";

export default function FooterMain() {
  return (
    <div className="row justify-content-end footer__main-container">
      <div className="col-10 footer__main-footer">
        <p>Copyright Ⓒ 2016-2024. All Rights Reserved.</p>

        <div className="footer__main-footer-icons">
          <a
            href="https://github.com/jsamborski310"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={githubIcon}
              alt="Github Icon."
              className="sidebar__menu-contact-icon"
            />
          </a>

          <a
            href="https://www.linkedin.com/in/juanita-samborski/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={linkedinIcon}
              alt="Linkedin Icon."
              className="sidebar__menu-contact-icon"
            />
          </a>

          <a href="mailto:juanita.samborski@gmail.com">
            <img
              src={emailIcon}
              alt="Email Icon."
              className="sidebar__menu-contact-icon"
            />
          </a>

          <a
            href="https://bit.ly/samborski-upwork"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={upworkIcon}
              alt="Upwork Icon."
              className="sidebar__menu-contact-icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
